import React from "react";
import { graphql } from "gatsby";
import Feed from "../components/Feed";
import Layout from "../components/Layout";
import Page from "../components/Page";
import RightSidebar from "../components/RightSidebar";
import Sidebar from "../components/Sidebar";

const TagTemplate = ({ data, pageContext }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    logo,
  } = data.site.siteMetadata;

  const { tag } = pageContext;

  const { edges } = data.allMarkdownRemark;
  const pageTitle = `タグ「${tag}」を含むすべての記事 - ${siteTitle}`;

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar />
      <Page logo={logo} title={`#${tag}`}>
        <Feed edges={edges} showCategory={true} />
      </Page>
      <RightSidebar />
    </Layout>
  );
};

export const query = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        subtitle
        logo {
          title
          subtitle
          path
          width
          height
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          tags: { in: [$tag] }
          template: { eq: "post" }
          draft: { eq: false }
        }
      }
      sort: { order: DESC, fields: [frontmatter___modified_at] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            created_at
            modified_at
            category
            description
          }
        }
      }
    }
  }
`;

export default TagTemplate;
